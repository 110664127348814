import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

window.addEventListener('load', function () {
  const Swiper = window.Swiper;
  if (document.querySelector('.testimonials__carousel')) {
    new Swiper('.testimonials__carousel', {
      direction: 'horizontal',
      loop: true,
      watchOverflow: false,
      paginationClickable: true,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      on: {
        init() {
          this.el.addEventListener('mouseenter', () => {
            this.autoplay.stop();
          });

          this.el.addEventListener('mouseleave', () => {
            this.autoplay.start();
          });
        },
      },
      mousewheel: {
        enabled: true,
        forceToAxis: true,
      },
      keyboard: true,
    });
  }

  const testimonials = document.querySelector('.testimonials');
  const tooltip = document.querySelector('.tooltip');

  if (typeof testimonials !== 'undefined' && tooltip) {
    testimonials.addEventListener('mouseover', (e) => {
      tooltip.classList.add('visible');
      tooltip.setAttribute('style', `left:${e.clientX}px;top:${e.clientY}px`);
    });

    testimonials.addEventListener('mouseout', (e) => {
      tooltip.classList.remove('visible');
      tooltip.setAttribute('style', `left:${e.clientX}px;top:${e.clientY}px`);
    });
  }
});
